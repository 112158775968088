import { Dispatch, FC, SetStateAction } from "react";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { QUERY_NAME } from "@bms/constants";
import { useQueryClient } from "@tanstack/react-query";

type IProps = {
  restProps: Appointments.AppointmentProps;
  setOpenScheduleDialog: Dispatch<SetStateAction<boolean>>;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  setCurrentDay: Dispatch<SetStateAction<any>>;
};

const AppointmentItem: FC<IProps> = ({
  setOpenScheduleDialog,
  setCurrentDay,
  restProps,
}) => {
  const { data } = restProps;

  const splitTitle: any = data.title?.split("/");
  const queryClient = useQueryClient();

  return (
    <Appointments.Appointment
      {...restProps}
      onClick={(e) => {
        setCurrentDay({
          startDate: e.data.startDate,
          endDate: e.data.endDate,
        });
        setOpenScheduleDialog(true);
        queryClient.removeQueries([QUERY_NAME.SHIFT_SCHEDULES]);
      }}
      className="content"
    >
      <div className="content-container">
        <div className="content-wrapper">
          <p
            style={{
              ...(splitTitle.length === 1 && {
                color: "#838383",
                marginTop: "3px",
              }),
            }}
          >
            {splitTitle[0]}
          </p>
          <p>{splitTitle[1]}</p>
        </div>
      </div>
    </Appointments.Appointment>
  );
};

export default AppointmentItem;
