import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { useFetchOneServiceCard, useSubmitWorkCost } from "@bms/hooks";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./schema";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useWorkCost = (props: ReceivedProps) => {
  const { id } = useParams();
  const { mutate, isLoading: isLoadingButton } = useSubmitWorkCost();
  const { data, isLoading, isFetching, isSuccess } = useFetchOneServiceCard(id);

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const initialValues = {
    busines_trip_fee: "",
    work_fee: "",
    disposal_fee: "",
    total_material_cost: "",
    total: "",
    work_cost_items: [
      {
        material_cost: "",
        product_name: "",
        part_number: "",
        quantity: "",
        unit: "",
      },
    ],
  };

  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation()),
    defaultValues: initialValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "work_cost_items",
  });

  const onSubmit = (values: any) => {
    mutate(
      {
        payload: {
          ...values,
          id: data?.work_cost?.id,
          service_card_id: id,
          has_notification: true,
        },
        workCostsId: data?.work_cost?.id,
      },
      {
        onSuccess() {
          openNotification();
        },
      }
    );
  };

  const handleValue = () => {
    const workCostItems: any =
      getValues("work_cost_items")?.length < 0
        ? 0
        : Math.floor(
            getValues("work_cost_items").reduce(
              (cur: any, value: any) =>
                cur +
                (Number(value?.material_cost) * Number(value?.quantity) || 0),
              0
            )
          );
    const businesTripFee = +getValues("busines_trip_fee") || 0;
    const workFee = +getValues("work_fee") || 0;
    const disposalFee = +getValues("disposal_fee") || 0;
    setValue("total_material_cost", workCostItems || "0");
    setValue(
      "total",
      businesTripFee + workFee + disposalFee + workCostItems || "0"
    );
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.work_cost) {
        reset(data.work_cost);
      }
    }
  }, [data?.work_cost, isSuccess, reset]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    control,
    errors,
    fields,
    isLoading,
    isFetching,
    isLoadingButton,
    openModalDelete,
    remove,
    setOpenModalDelete,
    handleValue,
    watch,
    onSubmit,
    append,
    setValue,
    getValues,
    register,
    handleSubmit,
  };
};

export type Props = ReturnType<typeof useWorkCost>;

export default useWorkCost;
