import * as yup from "yup";
import moment from "moment";
import dayjs from "dayjs";

import { DATE_TIME_FORMAT, ERRORS_MESSAGE } from "@bms/constants";
import { isEmpty } from "lodash";

export const validation = (dataLeave: any) => {
  return yup.object().shape({
    // Tab1
    working_status: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    invoice_status: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    confirm_payment_status: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),

    // Tab2
    order_date: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    // building_id: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    // resident_id: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    // support_type: yup
    //   .array()
    //   .nullable()
    //   .min(1, ERRORS_MESSAGE["REQUIRED"])
    //   .required(ERRORS_MESSAGE["REQUIRED"]),
    // address_payment_type: yup
    //   .array()
    //   .nullable()
    //   .min(1, ERRORS_MESSAGE["REQUIRED"])
    //   .required(ERRORS_MESSAGE["REQUIRED"]),
    move_in_date: yup
      .string()
      .nullable()
      .test("", "YYYY-MM-DDの形式で入力してください", (value) => {
        const isValid = dayjs(value, "YYYY-MM-DD", true).isValid();
        if (isEmpty(value)) return true;
        if (value && isValid) return true;
        return false;
      }),

    // Tab3
    department_in_charge: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    reception_staff_id: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    contact_person: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    contract_reception_id: yup
      .string()
      .nullable()
      .required(ERRORS_MESSAGE["REQUIRED"]),
    employee_in_charge_id: yup
      .string()
      .test("is-on-leave", dataLeave?.message, (value: any) => {
        if (!value) return true;
        return !(dataLeave?.success && dataLeave?.message);
      }),

    // Tab4
    // request_order_date: yup
    //   .string()
    //   .nullable()
    //   .required(ERRORS_MESSAGE["REQUIRED"]),
    content: yup.string().nullable().required(ERRORS_MESSAGE["REQUIRED"]),
    // start_working_hour: yup.string(),
    //   .nullable()
    //   .required(ERRORS_MESSAGE["REQUIRED"]),
    end_working_hour: yup
      .string()
      .test("", "終了時間は開始時間の後に選択してください。", function (value) {
        const { start_working_hour } = this.parent;
        if (isEmpty(value) && isEmpty(start_working_hour)) {
          return true;
        } else {
          if (start_working_hour && isEmpty(value)) return false;
        }

        return moment(value, DATE_TIME_FORMAT.TIME).isSameOrAfter(
          moment(start_working_hour, DATE_TIME_FORMAT.TIME)
        );
      })
      .nullable(),
    return_order_date: yup
      .string()
      .nullable()
      .when(
        "request_order_date",
        (requestOrderDate: any, schema: any, value) => {
          const currentValue = dayjs(value.value).format("YYYY-MM-DD");
          if (value?.value && requestOrderDate) {
            return schema.test(
              "",
              "再訪問日は訪問日より前の日付で設定できません。",
              () => {
                return dayjs(currentValue).isAfter(
                  dayjs(requestOrderDate).format("YYYY-MM-DD")
                );
              }
            );
          }
          return schema;
        }
      ),
    contruction_subject: yup
      .string()
      .nullable()
      .max(25, "工事件名は25文字以内で入力してください。"),
  });
};
