import { FC } from "react";
import { TextField, InputLabel } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import { AddIcon, Trash } from "@bms/assets";
import { Loading, ModalDelete } from "@bms/components";
import useWorkCost, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";

const WorkCostLayout: FC<Props> = ({
  errors,
  fields,
  isLoading,
  isFetching,
  isLoadingButton,
  openModalDelete,
  control,
  remove,
  setOpenModalDelete,
  handleValue,
  onSubmit,
  append,
  setValue,
  getValues,
  register,
  handleSubmit,
}) => {
  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="work-cost">
        <div className="field-wrapper">
          <div className="input-field">
            <InputLabel>出張料</InputLabel>
            <Controller
              name="busines_trip_fee"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  {...register("busines_trip_fee")}
                  value={value}
                  onValueChange={({ value }) => {
                    setValue("busines_trip_fee", value);
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="出張料を入力"
                  customInput={TextField}
                />
              )}
            />
            {errors?.busines_trip_fee && (
              <p className="error">{errors.busines_trip_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>作業代</InputLabel>
            <Controller
              name="work_fee"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  {...register("work_fee")}
                  value={value}
                  onValueChange={({ value }) => {
                    setValue("work_fee", value);
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="作業代を入力"
                  customInput={TextField}
                />
              )}
            />
            {errors?.work_fee && (
              <p className="error">{errors.work_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>処分代</InputLabel>
            <Controller
              name="disposal_fee"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  {...register("disposal_fee")}
                  value={value}
                  onValueChange={({ value }) => {
                    setValue("disposal_fee", value);
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="処分代を入力"
                  customInput={TextField}
                />
              )}
            />
            {errors?.disposal_fee && (
              <p className="error">{errors.disposal_fee?.message}</p>
            )}
          </div>

          <div className="input-field">
            <InputLabel>部材代(合計)</InputLabel>
            <Controller
              name="total_material_cost"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  disabled
                  {...register("total_material_cost")}
                  value={value || ""}
                  onValueChange={({ value }) => {
                    setValue("total_material_cost", value);
                    handleValue();
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="自動で合計金額算出されます"
                  customInput={TextField}
                />
              )}
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="input-field">
            <Controller
              name="total"
              control={control}
              render={({ field: { value } }) => (
                <NumericFormat
                  thousandSeparator=","
                  decimalSeparator="."
                  customInput={TextField}
                  {...register("total")}
                  value={value || ""}
                  variant="standard"
                  label="合計"
                  disabled={true}
                  InputLabelProps={{
                    shrink:
                      parseInt(getValues("total")) !== 0 ||
                      parseInt(getValues("total")) === 0,
                  }}
                />
              )}
            />
          </div>
        </div>

        {fields.map((_, index) => (
          <div className="field-wrapper additional-field" key={index}>
            <div className="input-field">
              <InputLabel>部材代</InputLabel>
              <Controller
                name={`work_cost_items.${index}.material_cost`}
                control={control}
                render={({ field: { value } }) => (
                  <NumericFormat
                    value={value}
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={0}
                    isAllowed={(values) => {
                      const { floatValue, formattedValue } = values;
                      return (
                        !formattedValue.includes("-") &&
                        formattedValue.length <= 10 &&
                        (floatValue === undefined ||
                          (floatValue >= 0 && floatValue <= 99999999))
                      );
                    }}
                    customInput={TextField}
                    {...register(`work_cost_items.${index}.material_cost`)}
                    name={`work_cost_items.${index}.material_cost`}
                    onValueChange={({ value }) => {
                      setValue(`work_cost_items.${index}.material_cost`, value);
                      handleValue();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                    placeholder="作業代を入力"
                  />
                )}
              />
            </div>
            <div className="input-field">
              <InputLabel>品名</InputLabel>
              <TextField
                {...register(`work_cost_items.${index}.product_name`)}
                name={`work_cost_items.${index}.product_name`}
                placeholder="品名を入力"
                // inputProps={{ maxLength: 25 }}
              />
            </div>
            <div className="input-field">
              <InputLabel>品番</InputLabel>
              <TextField
                {...register(`work_cost_items.${index}.part_number`)}
                name={`work_cost_items.${index}.part_number`}
                placeholder="品番を入力"
              />
            </div>
            <div className="input-field">
              <InputLabel>数量</InputLabel>
              <Controller
                name={`work_cost_items.${index}.quantity`}
                control={control}
                render={(props: any) => (
                  <NumericFormat
                    {...props}
                    value={props?.field?.value}
                    {...register(`work_cost_items.${index}.quantity`)}
                    onValueChange={({ value }) => {
                      setValue(`work_cost_items.${index}.quantity`, value);
                      handleValue();
                    }}
                    decimalScale={2}
                    isAllowed={(values) => {
                      const { formattedValue } = values;
                      return formattedValue.length <= 5;
                    }}
                    thousandSeparator=","
                    decimalSeparator="."
                    name={`work_cost_items.${index}.quantity`}
                    placeholder="数量を入力"
                    customInput={TextField}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                  />
                )}
              />
            </div>

            <div
              className="input-field"
              style={{
                marginBottom: index !== fields.length - 1 ? "69px" : 0,
              }}
            >
              <InputLabel>単位</InputLabel>
              <TextField
                {...register(`work_cost_items.${index}.unit`)}
                name={`work_cost_items.${index}.unit`}
                placeholder="単位名を入力"
                inputProps={{ maxLength: 5 }}
              />
            </div>

            {index !== 0 && (
              <div
                className="btn-delete"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 27,
                }}
              >
                <img src={Trash} onClick={() => setOpenModalDelete(true)} />
              </div>
            )}
            <ModalDelete
              isOpen={openModalDelete}
              isSubmitting={false}
              onDeleteRecord={async () => {
                remove(index);
                setOpenModalDelete(false);
              }}
              onClose={() => setOpenModalDelete(false)}
            />
          </div>
        ))}

        <button
          className="btn-add"
          onClick={(e) => {
            e.preventDefault();
            append({
              material_cost: "",
              product_name: "",
              part_number: "",
              quantity: "",
              unit: "",
            });
            return false;
          }}
          onKeyPress={(e) => {
            e.preventDefault();
            return false;
          }}
          onKeyDown={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <img src={AddIcon} />
          <p>項目追加</p>
        </button>

        <LoadingButton
          loadingPosition="start"
          loading={isLoadingButton}
          type="submit"
          variant="outlined"
          className="btn btn-keep"
          onClick={handleSubmit(onSubmit)}
        >
          <p>保存</p>
        </LoadingButton>
      </div>
    </Styles>
  );
};

const WorkCost: FC<ReceivedProps> = (props) => (
  <WorkCostLayout {...useWorkCost(props)} />
);

export default WorkCost;
